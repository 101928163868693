var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    on: {
      "before-enter": _vm.beforeEnterAnimation,
      "enter": _vm.enterAnimation,
      "before-leave": _vm.beforeLeaveAnimation
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }