var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-rating-bar"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('div', {
    staticClass: "sygni-rating-bar__container"
  }, [_vm._l([].concat(Array(_vm.value)), function (_) {
    return _c('div', {
      key: _,
      staticClass: "sygni-rating-bar__element sygni-rating-bar__element--active"
    });
  }), _vm._l([].concat(Array(_vm.max - _vm.value)), function (_) {
    return _c('div', {
      key: _ + _vm.max,
      staticClass: "sygni-rating-bar__element"
    });
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }