































import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from 'vue-property-decorator';
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {KPIs} from "@/modules/genprox/modules/profile/models/KPIs.interface";

Component.registerHooks(['validations'])
@Component({
  components: {SygniContainerTitle, SygniCard}
})

export default class ProfileInvestorsDashboard extends Vue {
  @Prop() data!: KPIs;
}
