var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile-investor-main-content"
  }, [_c('profile-investors-dashboard', {
    attrs: {
      "data": _vm.investorsKPIs
    }
  }), _c('div', {
    staticClass: "active-products"
  }, [_c('sygni-container-title', [_vm._v(" Products ")]), _c('profile-products-table', {
    attrs: {
      "tableData": _vm.productsTable,
      "viewType": _vm.viewType
    }
  })], 1), _c('div', {
    staticClass: "transaction-history"
  }, [_c('sygni-container-title', [_vm._v(" Transaction history ")]), _c('profile-transaction-history-table', {
    attrs: {
      "viewType": _vm.viewType,
      "tableData": _vm.transactionsTable
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }