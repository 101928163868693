




















import Vue from 'vue'
import Component from 'vue-class-component'
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import ProfileTransactionHistoryTable
  from "@/modules/genprox/modules/profile/components/profile-dashboard/ProfileTransactionHistoryTable.vue";
import ProfileInvestorsDashboard
  from "@/modules/genprox/modules/profile/components/profile-dashboard/ProfileInvestorsDashboard.vue";
import {mapState} from "vuex";
import {Product} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import ProfileProductsTable
  from "@/modules/genprox/modules/profile/components/profile-dashboard/ProfileProductsTable.vue";
import {KPIs} from "@/modules/genprox/modules/profile/models/KPIs.interface";
import {UserWallet} from "@/modules/genprox/modules/profile/models/user-wallet.interface";
import {Transaction} from "@/modules/genprox/modules/profile/models/transaction.interface";
import {TableData} from "@/modules/genprox/modules/profile/store/types";

@Component({
  components: {ProfileTransactionHistoryTable, ProfileProductsTable, SygniContainerTitle, ProfileInvestorsDashboard},
  computed: mapState('profile', {
    wallet: (state: any) => state.wallet,
    productsTable: (state: any) => state.productsTable,
    transactionsTable: (state: any) => state.transactionsTable,
  }),
})
export default class ProfileInvestorMainContent extends Vue {

  wallet!: UserWallet;

  get investorProducts(): Array<Product> {
    return this.productsTable.items;
  }

  productsTable!: TableData<Product>;
  transactionsTable!: TableData<Transaction>;

  mounted() {
  }

  get viewType(): 'investor' | 'owner' {
    return (this.$route.params.viewType as 'investor' | 'owner') ?? 'owner';
  }

  get investorsKPIs(): KPIs {
    if( this.viewType === 'owner' ){
      return this.wallet.KPIs;
    } else if (this.viewType === 'investor'){
      return (this.$route.params.keyKPIsByGuest as any);
    }
    return null;
  }


}
