




































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {BSpinner, BvTableField} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import InvestorsInnerTable from "@/modules/genprox/modules/fund/modules/fundraising/components/InvestorsInnerTable.vue";
import {Product } from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import {Statues} from "@/shared/interfaces/Statues";
import {Prop} from "vue-property-decorator";

@Component({
  components: {
    SygniCircleButton,
    InvestorsInnerTable, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner }
})
export default class ProfileProductsTable extends SygniTable<Product> {

  @Prop({}) viewType!: 'investor' | 'owner';

  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'code', sortable: false, label: 'CODE', class: 'code'},
    { key: 'sourceName', sortable: false, label: 'SOURCE', class: 'source-name'},
    { key: 'type', sortable: false, borderless: true , label: 'TYPE', class: 'type'},
    { key: 'agreementDate', sortable: false, label: 'AGREEMENT DATE', class:'agreement-date' },
    { key: 'status', sortable: false, label: "STATUS", class: 'status' },
    { key: 'value', sortable: false, label: "VALUE", class: 'value'},
  ];

  constructor() {
    super();
  }

  setQuery(){
    this.$store.commit(this.setProductQueryCommit, this.localTableQuery);
  }

  async getItems(){
    const items = await this.$store.dispatch(this.productsAction, this.productsActionPayload);
    this.$store.commit('profile/setProductsTableItems', items);
  }

  async onCodeClick(product: Product){
    if(this.viewType === 'investor'){
      if(product.status === Statues.pending){
        await this.$router.push({name: 'acceptProductByIdByLegalEntity', params: {id: product.id}});
      } else {
        await this.$router.push({name: 'productSummaryForLegalEntity', params: {id: product.id}});
      }
    } else if (this.viewType === 'owner'){
      if( product.status === Statues.awaiting){
        await this.$router.push({name: 'acceptProductById', params: { id: product.id }});
      } else {
        await this.$router.push({name: 'productSummaryForInvestmentClient', params: {id: product.id}});
      }
    }
  }

  get setProductQueryCommit(): string {
    if(this.viewType === 'owner') {
      return 'profile/setProductsQuery';
    } else if (this.viewType === 'investor'){
      return 'investors/setProductsQueryNEW'
    }
    return '';
  }

  get productsAction(): string {
    if(this.viewType === 'owner') {
      return 'profile/getWalletProductsForLoggedUser';
    } else if (this.viewType === 'investor'){
      return 'investors/getInvestorProductsNEW'
    }
    return '';
  }

  get productsActionPayload(): string {
    if(this.viewType === 'owner') {
      return null;
    } else if (this.viewType === 'investor'){
      return this.$route.params.id;
    }
    return '';
  }


  mounted(){
    this.onMounted();
    this.setBorderlessTds();
  }

  statusClass(status: Statues) {
    switch(status.toUpperCase()) {
      case(Statues.new): return 'black';
      case(Statues.active): return 'primary';
      case(Statues.invited): return 'success';
      case(Statues.awaiting): return 'success';
      case(Statues.pending): return 'black';
      case(Statues.rejected): return 'danger';
      case(Statues.declined): return 'disabled';
    }
    return '';
  }

}

