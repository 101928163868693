











import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";

@Component
export default class FadeInOut extends Vue {

  @Prop({default: '1000'}) readonly enterTime!: string;
  @Prop({default: '1000'}) readonly leaveTime!: string;
  @Prop({default: 10}) readonly enterDelayTime!: number;
  @Prop({default: 10}) readonly leaveDelayTime!: number;

  beforeEnterAnimation(el: HTMLDivElement): void {
    el.style.transitionDuration = `${this.enterTime}ms`;
    el.style.transitionProperty = 'opacity'
    el.style.opacity = '0';
  }

  enterAnimation(el: HTMLDivElement){
    setTimeout( () => {
      el.style.opacity = '1';
    }, this.enterDelayTime);
  }


  beforeLeaveAnimation(el: HTMLDivElement){
    el.style.transitionDuration = `${this.leaveTime}ms`;
    el.style.transitionProperty = 'opacity'
    setTimeout( () => {
      el.style.opacity = '0';
    }, this.leaveDelayTime);
  }

}
