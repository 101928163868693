











import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";

@Component({
  components: {}
})
export default class SygniRatingBar extends Vue {
  @Prop() label!: string;
  @Prop() max!: number;
  @Prop() value!: number;
}
