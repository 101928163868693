var render = function () {
  var _vm$data, _vm$data2, _vm$data3, _vm$data4, _vm$data5, _vm$data6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sygni-card', {
    staticClass: "profile-investor-dashboard"
  }, [_c('sygni-container-title', [_vm._v(" Investor's Dashboard ")]), _c('div', {
    staticClass: "profile-investor-dashboard__information-container"
  }, [_c('div', {
    staticClass: "profile-investor-dashboard__information"
  }, [_c('div', {
    staticClass: "profile-investor-dashboard__label label"
  }, [_vm._v("INVESTED FUNDS")]), _c('div', {
    staticClass: "profile-investor-dashboard__value"
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.investmentFunds.value, 2)) + " " + _vm._s((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.investmentFunds.currency) + " ")])]), _c('div', {
    staticClass: "profile-investor-dashboard__information"
  }, [_c('div', {
    staticClass: "profile-investor-dashboard__label label"
  }, [_vm._v("PORTFOLIO VALUATIONS")]), _c('div', {
    staticClass: "profile-investor-dashboard__value"
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : _vm$data3.portfolioValuations.value, 2)) + " " + _vm._s((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : _vm$data4.portfolioValuations.currency) + " ")])]), _c('div', {
    staticClass: "profile-investor-dashboard__information"
  }, [_c('div', {
    staticClass: "profile-investor-dashboard__label label"
  }, [_vm._v("COMPANY COUNT")]), _c('div', {
    staticClass: "profile-investor-dashboard__value"
  }, [_vm._v(" " + _vm._s((_vm$data5 = _vm.data) === null || _vm$data5 === void 0 ? void 0 : _vm$data5.companyCount))])]), _c('div', {
    staticClass: "profile-investor-dashboard__information"
  }, [_c('div', {
    staticClass: "profile-investor-dashboard__label label"
  }, [_vm._v("DEAL COUNT")]), _c('div', {
    staticClass: "profile-investor-dashboard__value"
  }, [_vm._v(_vm._s((_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : _vm$data6.dealCount))])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }