

















































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniLinkButton from "@/components/buttons/SygniLinkButton.vue";
import ProfileCrmData from "@/modules/genprox/modules/profile/components/profile-dashboard/ProfileCrmData.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniCard from "@/components/layout/SygniCard.vue";
import FadeInOut from "@/components/animations/FadeInOut.vue";
import {BusinessLink, ProfileDataState} from "@/modules/genprox/modules/profile/store/types";
import {Prop} from "vue-property-decorator";
import {CRMData} from "@/modules/genprox/modules/profile/models/crm-data.interface";
import {Investor} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import {Statues} from "@/shared/interfaces/Statues";
import { UserContext} from "@/modules/genprox/models/User";

Component.registerHooks(['validations'])
@Component({
  components: {
    FadeInOut,
    SygniCard, UserPresentation, SygniContainerTitle, ProfileCrmData, SygniLinkButton, SygniRoundedButton}
})

export default class ProfileSideBar extends Vue {
  showMenu: boolean = true;
  @Prop() profile!: ProfileDataState & Investor;
  @Prop() businessLinks!: Array<BusinessLink>;
  @Prop() viewType!: 'guest' | 'owner' | 'investor';
  @Prop() activeUserContext!: UserContext;
  @Prop() investorId!: string;

  get investorStatus(): string {
    if(this.viewType === "investor"){
      return this.profile.status
    }
    return null;
  }

  get crmData(): CRMData {
    return {
      focusArea: this.profile.generalData.focusArea,
      industries: this.profile.generalData.industry,
      investmentKnowledge: this.profile.generalData.investmentKnowledge,
      investmentPotential: this.profile.generalData.investmentPotential,
      notes: this.profile.generalData.notes,
    }
  }

  get userContexts() {
    return this.$store.getters['genprox/userContexts']
  }

  goToEntity(link: BusinessLink) {
    const context = this.userContexts?.find((el: any) => el?.id === link?.legalEntityId)
    this.$root.$emit('setUserContext', context)
  }

  statusClass(status: Statues) {
    switch(status) {
      case(Statues.active): return 'primary';
      case(Statues.invited): return 'success';
      case(Statues.awaiting): return 'success';
      case(Statues.pending): return 'black';
      case(Statues.rejected): return 'danger';
      case(Statues.declined): return 'disabled';
    }
    return '';
  }

}
