var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile-transaction-history-table"
  }, [_c('b-table', _vm._g(_vm._b({
    staticClass: "sygni-b-table sygni-b-secondary-table",
    attrs: {
      "fields": _vm.tableFields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(agreementDate)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm._f("dateWithoutTime")(rowData.item.agreementDate)) + " ")];
      }
    }, {
      key: "cell(value)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.value.value, 2)) + " " + _vm._s(rowData.item.value.currency) + " ")];
      }
    }, {
      key: "cell(type)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(rowData.item.instrumentType) + " ")];
      }
    }, {
      key: "cell(code)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(rowData.item.investmentName) + " - " + _vm._s(rowData.item.instrumentType) + " ")];
      }
    }])
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }