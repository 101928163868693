var render = function () {
  var _vm$profile;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'profile-side-menu': true,
      'profile-side-menu--hide': !_vm.showMenu
    }
  }, [_c('fade-in-out', {
    attrs: {
      "enterTime": 550,
      "leave-time": 550
    }
  }, [_vm.showMenu ? _c('div', {
    staticClass: "profile-side-menu-container"
  }, [_vm.investorStatus ? _c('div', {
    staticClass: "profile-side-menu__header"
  }, [_c('div', {
    staticClass: "investor-status"
  }, [[_c('div', {
    staticClass: "label"
  }, [_vm._v(" INVESTOR STATUS ")]), _c('sygni-rounded-button', {
    class: "filled round ".concat(_vm.statusClass(_vm.investorStatus)),
    attrs: {
      "hoverable": false
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.investorStatus)) + " ")])]], 2)]) : _vm._e(), ((_vm$profile = _vm.profile) === null || _vm$profile === void 0 ? void 0 : _vm$profile.clientType) !== 'legal-entity' ? _c('profile-crm-data', {
    attrs: {
      "crmData": _vm.crmData,
      "viewType": _vm.viewType,
      "investorId": _vm.investorId
    }
  }) : _vm._e()], 1) : _vm._e()]), _c('fade-in-out', {
    attrs: {
      "enterTime": 1500,
      "leave-time": 350
    }
  }, [!_vm.showMenu ? _c('sygni-link-button', {
    attrs: {
      "arrowSide": _vm.showMenu ? 'right' : 'left'
    },
    on: {
      "click": function click($event) {
        _vm.showMenu = !_vm.showMenu;
      }
    }
  }, [_vm._v("Show side menu ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }