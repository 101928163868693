






































































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {Prop} from 'vue-property-decorator';
import SygniCard from "@/components/layout/SygniCard.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniSocialsButton from "@/components/buttons/SygniSocialsButton.vue";
import { BSpinner } from 'bootstrap-vue'
import {ProfileDataState} from "@/modules/genprox/modules/profile/store/types";
import {mapGetters} from "vuex";
import {GlobalSelects} from "@/modules/shared/dictionary-store/types";

Component.registerHooks(['validations'])
@Component({
  components: {SygniSocialsButton, UserPresentation, SygniCard, SygniRoundedButton, BSpinner},
  computed: {
    ...mapGetters('dictionary', {
      globalSelects: 'globalSelects'
    })
  }
})

export default class ProfilePresentation extends Vue {
  @Prop() data!: ProfileDataState;
  globalSelects!: GlobalSelects;
  isImpersonateLoading: boolean = false;

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get name() {
    if (this.data?.clientType === 'legal-entity') {
      return this.data?.generalData?.name
    }

    return this.data?.generalData?.firstName + ' ' + this.data?.generalData?.surname
  }

  async impersonateInvestor() {
    if (!this.isImpersonateLoading) {
      this.isImpersonateLoading = true
  
      try {
        const resp = await this.$store.dispatch('investors/getImpersonateInvestorToken', this.$route?.params?.id)

        if (resp?.advisorPanel && resp?.token) {
          const domain = resp?.advisorPanel?.includes('https://') || resp?.advisorPanel?.includes('http://') ? resp?.advisorPanel : `https://${resp?.advisorPanel}`
          const token = resp?.token
  
          const redirectionLink = `${domain}/investor-login/?token=${token}`

          window.open(redirectionLink, '_blank').focus()
        } else {
          throw new Error('impersonate-failure')
        }

      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.$notify({
          type: 'error',
          title: 'An error occurred.',
          text: errorMessage
        });
      }
  
      this.isImpersonateLoading = false
    }
  }

  translateCountry(countryValue: string): string {
    if(this.globalSelects.countries.length === 0) return countryValue;
    return this.globalSelects.countries.find( country => country.value === countryValue).label ?? countryValue;
  }

  mounted(){
  }
}
